<template>
  <vs-row>   
    <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
        <SaveCancel :SaveAllowed = !userFullDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>
      </vs-card>
    </vs-col>    
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold">{{$t('USERADMIN.EDIT.PROFILE.TITEL')}}</h3>
        </div>
        <div v-if="!showUpload">
          <div slot="media" class="text-center">
            <vs-avatar class="mt-3" size="200px" v-bind:src="'data:image/jpeg;base64,'+displayUserImg"  />
          </div>
          <div class="text-center">
            <vs-button color="primary" type="border" @click="toggleShowUpload">{{$t('USERADMIN.EDIT.BUTTON.UPLOAD')}}</vs-button>
          </div>
        </div>
        <div v-if="showUpload" class="text-center">
          <vue-dropzone id="userImgDrop" ref="userImgDrop" :options="dropOptions" class="mb-3" v-on:vdropzone-success="dropzoneSuccess" v-on:vdropzone-error="dropzoneError" v-on:vdropzone-sending="dropzoneSending"></vue-dropzone>
          <vs-button color="primary" type="border" @click="toggleShowUpload">{{$t('COM.CLOSE')}}</vs-button>
        </div>
      </vs-card>
    </vs-col>
    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold">{{$t('USERADMIN.EDIT.USERDATA.TITEL')}} <vs-icon icon="account_circle"></vs-icon></h3>
        </div>
        <vs-divider icon="account_circle" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.USERNAME')"  class="inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.USERNAME')"  v-model="userName"  :danger="userNameFail" val-icon-danger="clear"/>
          <vs-input 
          :label="$t('USERADMIN.EDIT.USERDATA.LABEL.EMAIL')"  
          class="ml-3 inputx" 
          :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.EMAIL')"  
          v-model="email" 
          :success="emailOkay"
          :danger="emailFail"
          val-icon-success="done"
          val-icon-danger="clear" />
          <vs-input 
          :label="$t('USERADMIN.EDIT.USERDATA.LABEL.EMAIL2')"  
          class="ml-3 inputx" 
          :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.EMAIL2')"  
          v-model="email2" 
          :success="email2Okay"
          :danger="email2Fail"
          val-icon-success="done"
          val-icon-danger="clear" />
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.PARTNER_ID')"  class=" ml-3 inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.PARTNER_ID')" v-model="partnerID" :danger="partnerIDFail" val-icon-danger="clear" />
        </div>        
        <vs-divider icon="contacts" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.TITEL')"  class="inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.TITEL')"  v-model="titel" />
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.VORNAME')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.VORNAME')"  v-model="firstName" :danger="firstNameFail" val-icon-danger="clear"/>
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.VORNAME2')"  class=" ml-3 inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.VORNAME2')" v-model="secondName" />
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.NACHNAME')"  class="ml-3 inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.NACHNAME')" v-model="lastName" :danger="lastNameFail" val-icon-danger="clear"/>
        </div>
        <vs-divider icon="phone" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.TELEFON')"  class="inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.TELEFON')"  v-model="telefon" :danger="telefonFail" val-icon-danger="clear"/>
          <vs-input :label="$t('USERADMIN.EDIT.USERDATA.LABEL.TELEFON2')"   class="ml-3 inputx" :placeholder="$t('USERADMIN.EDIT.USERDATA.LABEL.PLACEHOLDER.TELEFON2')" v-model="telefon2" />
          <vs-select 
            :label="$t('USERADMIN.EDIT.USERDATA.LABEL.SPRACHE')"
            class="ml-3 "
            v-model="language_fk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in languages" />
          </vs-select>
          <vs-select 
            :label="$t('USERADMIN.EDIT.USERDATA.LABEL.TIMEMODEL')"
            class="ml-3 "
            v-model="timemodel_fk">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in timemodelsForSelect" />
          </vs-select>
          <vs-input type="number" :label="$t('USERADMIN.EDIT.USERDATA.LABEL.TIME_SALDO_START')"   class="ml-3 inputx" v-model="time_start_saldo" />
        </div>
        <vs-divider icon="mail" position="center" ></vs-divider>
        <h5 class="font-weight-bold">{{$t('USERADMIN.EDIT.USERDATA.HEADER.ABW_ADDR')}}</h5>
        <ul class="list-inline mb-0 mt-3">
          <li class="list-inline-item">
            <vs-checkbox :disabled="isPageModeNew" v-model="use_address2">{{$t('USERADMIN.EDIT.USERDATA.NEBENADRESSE')}}</vs-checkbox>
          </li>
          <li class="list-inline-item">
            <vs-checkbox :disabled="isPageModeNew" v-model="use_address_acc">{{$t('USERADMIN.EDIT.USERDATA.RECHNUNGSADRESSE')}}</vs-checkbox>
          </li>
          <li class="list-inline-item">
            <vs-checkbox :disabled="isPageModeNew" v-model="use_address_dev">{{$t('USERADMIN.EDIT.USERDATA.LIEFERADRESSE')}}</vs-checkbox>
          </li>          
        </ul>
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.ADRESSE.TITEL')}} <vs-icon class="ml-1" icon="mail"></vs-icon>
              <div v-if="!isPageModeNew" class="ml-auto text-right">
                <vs-button  @click="toggleEditModeAddr1" size="small" color="primary" type="filled" v-if="!editModeAddr1" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeAddr1" size="small" color="primary" type="filled" v-if="editModeAddr1" icon="close"></vs-button>  
              </div>
          </h3>
        </div>
        <Address :NewUser=isPageModeNew :IsRequired=telefonAdress1_fkFail :EditMode=editModeAddr1 :AddrId=address1_fk :OwnerId=getUserId :OwnerType=GetOwnerType() v-on:SelectedAddressChanged="selectedAddress1Changed" />
      </vs-card>
    </vs-col>
    <vs-col v-if="use_address2" vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.USERDATA.NEBENADRESSE')}} <vs-icon class="ml-1" icon="mail"></vs-icon>
              <div class="ml-auto text-right">
                <vs-button  @click="toggleEditModeAddr2" size="small" color="primary" type="filled" v-if="!editModeAddr2" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeAddr2" size="small" color="primary" type="filled" v-if="editModeAddr2" icon="close"></vs-button>                  
              </div>
          </h3>
        </div>
        <Address :NewUser=isPageModeNew :IsRequired=false :EditMode=editModeAddr2 :AddrId=address2_fk :OwnerId=getUserId :OwnerType=GetOwnerType() v-on:SelectedAddressChanged="selectedAddress2Changed" />
      </vs-card>
    </vs-col>
    <vs-col v-if="use_address_acc" vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.USERDATA.RECHNUNGSADRESSE')}} <vs-icon class="ml-1" icon="domain"></vs-icon>
              <div class="ml-auto text-right">
                <vs-button  @click="toggleEditModeAddrAcc" size="small" color="primary" type="filled" v-if="!editModeAddrAcc" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeAddrAcc" size="small" color="primary" type="filled" v-if="editModeAddrAcc" icon="close"></vs-button>
              </div>
          </h3>
        </div>
        <Address :NewUser=isPageModeNew :IsRequired=false :EditMode=editModeAddrAcc :AddrId=address_acc_fk :OwnerId=getUserId :OwnerType=GetOwnerType() v-on:SelectedAddressChanged="selectedAddressAccChanged" />
      </vs-card>
    </vs-col>
    <vs-col v-if="use_address_dev" vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.USERDATA.LIEFERADRESSE')}}  <vs-icon class="ml-1" icon="apartment"></vs-icon>
              <div class="ml-auto text-right">
                <vs-button  @click="toggleEditModeAddrDev" size="small" color="primary" type="filled" v-if="!editModeAddrDev" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeAddrDev" size="small" color="primary" type="filled" v-if="editModeAddrDev" icon="close"></vs-button>                
              </div>          
          </h3>
        </div>
        <Address :NewUser=isPageModeNew :IsRequired=false :EditMode=editModeAddrDev :AddrId=address_dev_fk :OwnerId=getUserId :OwnerType=GetOwnerType() v-on:SelectedAddressChanged="selectedAddressDevChanged" />
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.USERDATA.BANK_ACCOUNT')}}  <vs-icon class="ml-1" icon="account_balance"></vs-icon>
              <div class="ml-auto text-right">
                <vs-button  @click="toggleEditModeBankAccount" size="small" color="primary" type="filled" v-if="!editModeBankAccount" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeBankAccount" size="small" color="primary" type="filled" v-if="editModeBankAccount" icon="close"></vs-button>                
              </div>          
          </h3>
        </div>
        <BankAccount :NewUser=isPageModeNew :IsRequired=bankAccountFk_fail :EditMode=editModeBankAccount :BankAccountId=bank_account_fk :OwnerId=getUserId :OwnerType=GetOwnerType() v-on:SelectedBankAccountChanged="selectedBankAccountChanged" />
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <AddTimeRecDlg ref="addTimeRecDlg" v-on:Success="LoadTimeRecsStd"></AddTimeRecDlg>
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('USERADMIN.EDIT.USERDATA.TIME_RECS_STD')}}  <vs-icon class="ml-1" icon="timer"></vs-icon>     
              <div class="ml-auto text-right">
                <vs-button  @click="AddTimeRec" size="small" color="primary" type="filled" icon="add"></vs-button>              
              </div>    
          </h3>
        </div>
        <vs-table
          search
          :data="timeRecsStd"
          :noDataText="$t('TIMEREC.NO_DATA')"
          stripe
          max-items="25" 
          pagination
          hoverFlat>

          <template slot="header">
            <h3>
              {{$t('TIMEREC.TABLE.HEADER')}}
            </h3>
          </template>
          <template slot="thead">                               
            <vs-th sort-key="start">
              {{$t('TIMEREC.TABLE.COL1')}}
            </vs-th>
            <vs-th sort-key="end">
              {{$t('TIMEREC.TABLE.COL2')}}
            </vs-th>
            <vs-th sort-key="diff">
              {{$t('TIMEREC.TABLE.COL3')}}
            </vs-th>
            <vs-th sort-key="type">
              {{$t('TIMEREC.TABLE.COL4')}}
            </vs-th>  
            <vs-th sort-key="description">
              {{$t('TIMEREC.TABLE.COL5')}}
            </vs-th>                                                                                                           
            <vs-th></vs-th>                                                                                                                                             
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
              <vs-td :data="data[indextr].start">
                {{DateToTimeString(data[indextr].start)}}
              </vs-td>                  
              <vs-td :data="data[indextr].end">
                {{DateToTimeString(data[indextr].end)}}
              </vs-td>   
              <vs-td :data="data[indextr].diff">
                {{data[indextr].diff}}
              </vs-td>   
              <vs-td :data="data[indextr].type">
                {{GetTimeRecTypeText(data[indextr].type)}}
              </vs-td> 
              <vs-td :data="data[indextr].description">
                {{data[indextr].description}}
              </vs-td>                                                                                                 
              <vs-td>
                <vs-button  @click="EditTimeRec(data[indextr])" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                <vs-button  @click="OpenConfirmDeleteTimeRec(data[indextr].id)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
              </vs-td>                    
                                                                                                                                                                                        
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </vs-col>
  </vs-row>
</template>
<script>

import Address from "./Address.vue";
import BankAccount from "./BankAccount.vue";
import SaveCancel from "../SaveCancel";
import {pagemode} from "../../../store/adminpages.module";
import helperMethods from "../../../helper/staticFuncHelper";
import {ownertype} from "../../../helper/staticFuncHelper";
import enumHelper from "../../../helper/enumHelper";
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../../../services/localstorage.service';
import AddTimeRecDlg from '../dialogs/AddTimeRecDlg.vue';

const localStorageService = LocalStorageService.getService();

export default {
  name: "UserData", 
  components: {
    Address,
    SaveCancel,
    BankAccount,
    vueDropzone,
    AddTimeRecDlg
  },
  props:[
    'PageMode'
  ],
   data: function () {
     return {
      editModeAddr1:false,
      editModeAddr2:false,
      editModeAddrAcc:false,
      editModeAddrDev:false,
      editModeBankAccount: false,
      emailOkay:false,
      emailFail:false,
      email2Okay:false,
      email2Fail:false,
      firstNameFail:false,
      partnerIDFail:false,
      userNameFail:false,
      lastNameFail:false,
      telefonFail:false,
      telefonAdress1_fkFail:false,
      bankAccountFk_fail:false,
      showUpload:false,
      imgstring:"",
      dropOptions: {
        url: this.$appConfig.apiBaseUrl+'/users/user/img/upload',
        maxFilesize: 1, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
        acceptedFiles:".jpg,.png,.bmp",
        forceFallback:false,
        dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
        dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
        dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
        dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
        dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
        dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
        dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
        dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
        dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
        dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
        dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
        dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES')
      },
     }
  },
  computed:{
      displayUserImg() {

      var filename = "";
      
      if(typeof(this.$store.state.users.userfull.userdata) != 'undefined')
      {
        if(this.$store.state.users.userfull.userdata != null)
          filename = this.$store.state.users.userfull.userdata.user_img; 
      }
      return filename; 
    },
    isPageModeNew(){
        return (this.PageMode == pagemode.NEW);
    },
    timemodelsForSelect(){
      return this.GetTimeModelsForSelect();
    },
    timeRecsStd(){
      var data = [];
      if(this.$store.state.timerec.timerecsStd.data != null)
      {
        data = this.$store.state.timerec.timerecsStd.data
      }
      return data;
    },
    userFullDataChanged()
    {
      var datachanged = JSON.stringify(this.$store.state.users.userfull.userdata) !== this.$store.state.users.userfull.userdataOld;

      this.$store.dispatch('unsavedData/setUserAdmin', datachanged);

      return datachanged;
    },
    getOwnUserGuid()
    {
      return this.$store.state.users.usermin.userGuid;
    },
    getUserId()
    {
      if(this.$store.state.users.userfull.userdata != null)
        return this.$store.state.users.userfull.userdata.user_fk;
      else
        return "";
    },
    languages: function(){
      return this.GetListOfLanguagesForSelect();
    },
    firstName: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.firstName;
      },
      set (value) {
        if(value != "")
          this.firstNameFail = false;        
        this.$store.dispatch('users/updateUserFullDataFirstName', value);
      }
    },
    secondName: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.secondName;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataSecondName', value);
      }
    },
    lastName: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.lastName;
      },
      set (value) {
        if(value != "")
          this.lastNameFail = false;        
        this.$store.dispatch('users/updateUserFullDataLastName', value);
      }
    },
    titel: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.titel;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataTitel', value);
      }
    },
    userName: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.userName;
      },
      set (value) {
        if(value != "")
          this.userNameFail = false;
        this.$store.dispatch('users/updateUserFullDataUserName', value);
      }
    },
    email: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.email;
      },
      set (value) {

        if(value != "")
        {
          if(this.ValidateEmail(value))
          {
            this.emailOkay = true;
            this.emailFail = false;
          }
          else
          {
            this.emailOkay = false;
            this.emailFail = true;
          }
        }
        this.$store.dispatch('users/updateUserFullDataEmail', value);
      }
    },
    email2: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.email2;
      },
      set (value) {

          if(this.ValidateEmail(value) || value == "")
          {
            this.email2Okay = true;
            this.email2Fail = false;
          }
          else
          {
            this.email2Okay = false;
            this.email2Fail = true;
          }
        
        this.$store.dispatch('users/updateUserFullDataEmail2', value);
      }
    },
    partnerID: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.partnerID;
      },
      set (value) {
        if(value != "")
          this.partnerIDFail = false;

        this.$store.dispatch('users/updateUserFullDataPartnerID', value);
      }
    },
    telefon: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.tel1;
      },
      set (value) {
        if(value != "")
          this.telefonFail = false;
        this.$store.dispatch('users/updateUserFullDataTel1', value);
      }
    },
    telefon2: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.tel2;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataTel2', value);
      }
    },
    address1_fk: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.address1_fk;
      },
      set (value) {
        if(value != -1)
          this.telefonAdress1_fkFail = false;
        this.$store.dispatch('users/updateUserFullDataAddress1_fk', value);
      }
    },
    address2_fk: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.address2_fk;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataAddress2_fk', value);
      }
    },
    address_acc_fk: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.address_acc_fk;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataAddress_acc_fk', value);
      }
    },
    address_dev_fk: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.address_dev_fk;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataAddress_dev_fk', value);
      }
    },
    use_address2:
    {
      get () {
        return this.$store.state.users.userfull.userdata.use_address2;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataUse_address2', value);
      }
    },
    use_address_acc:
    {
      get () {
        return this.$store.state.users.userfull.userdata.use_address_acc;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataUse_address_acc', value);
      }
    },
    use_address_dev:
    {
      get () {
        return this.$store.state.users.userfull.userdata.use_address_dev;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataUse_address_dev', value);
      }
    } ,
    language_fk:
    {
      get () {
        return this.$store.state.users.userfull.userdata.language_fk;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataLanguage_fk', value);
      }
    },
    timemodel_fk:
    {
      get () {
        return this.$store.state.users.userfull.userdata.time_model_fk;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataTimeModel_fk', value);
      }
    },
    time_start_saldo:
    {
      get () {
        return this.$store.state.users.userfull.userdata.time_start_saldo;
      },
      set (value) {
        this.$store.dispatch('users/updateUserFullDataTime_start_saldo', value);
      }
    }, 
    bank_account_fk: 
    {
      get () {
        return this.$store.state.users.userfull.userdata.bank_account_fk;
      },
      set (value) {
        if(value != -1)
          this.bankAccountFk_fail = false;
        this.$store.dispatch('users/updateUserFullDataBankAccount_fk', value);
      }
    }
  },
  watch:{
    displayUserImg(value){
      this.imgstring = value;
    },
    
    
  },
  methods:{
    ...helperMethods,
    ...enumHelper,
    Save: function ()
    {
      if(this.ValidateForm())
      {
        if(this.PageMode == pagemode.EDIT)
        {
          this.SaveUser();
        }
        else
        {
          if(this.PageMode == pagemode.NEW)
            this.NewUser();
        }
      }
      else
      {
        this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('USERADMIN.EDIT.USERDATA.REQUIRED')});
      }
    },
    toggleShowUpload(){
      this.showUpload = !this.showUpload;
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneSuccess(file, response){
      this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.SUCCESS.UPLOAD_IMG').replace("*1*",file.upload.filename)});
      this.$refs.userImgDrop.removeFile(file);
      this.$store.dispatch('users/getUserMinData', { root: true },{ root: true });
      this.$store.dispatch('users/getUserOverviewData', { root: true },{ root: true });
      this.$store.dispatch('users/getUserFullData', this.$store.state.users.userfull.userdata.userGuid);
      this.toggleShowUpload();
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneError(file, message, xhr){
      this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.ERROR.UPLOAD_IMG').replace("*1*",file.upload.filename)});
      this.$refs.userImgDrop.removeFile(file);
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneSending(file, xhr, formData) {
      formData.append('userid', this.getUserId);
    },
    LoadTimeRecsStd(){
      this.$store.dispatch('timerec/getTimeModels', { root: true },{ root: true }); 
      this.$store.dispatch('timerec/getTimeRecsStd', this.$store.state.users.userfull.userdata.userGuid); 
    },
    EditTimeRec(data){
      this.$refs.addTimeRecDlg.InitDlgData(data,true);
    },
    AddTimeRec(){
      this.$refs.addTimeRecDlg.ShowDlg(true);
    },
    OpenConfirmDeleteTimeRec(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: this.$t('COM.SURE.TITEL'),
        acceptText: this.$t('COM.DELETE'),
        cancelText:this.$t('COM.CANCEL'),
        text: this.$t('TIMEREC.QUESTION.DELETE'),
        accept: this.SendDeleteRequest,
        parameters:{"id": id}
      });
    
    },
    SendDeleteRequest: function(parameters)
    {
      this.$store.dispatch('timerec/deleteTimeRecStd', parameters.id)
      .then(response => {
        if(response.success == true)
        {
          this.LoadTimeRecsStd();
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('TIMEREC.SUCCESS.DELETE')});  
        }
        else
        {
          var text = this.$t('TIMEREC.WARNING.DELETE');
          text = text.replace("*1*",this.$t(response.text));
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
        }
      }, 
      error =>{

          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('TIMEREC.ERROR.DELETE').replace("*1*",error)});  
      });
    },
    NewUser: function ()
    {
      var adr = null;
      var bank_acc = null;
      var adr_data = this.$store.state.address.addressListUser.data;
      var bank_account_data = this.$store.state.bankaccount.bankaccountListUser.data;

      if(adr_data != null)
      {
        adr = adr_data[adr_data.length-1];
      }

      if(bank_account_data != null)
      {
        bank_acc = bank_account_data[bank_account_data.length-1];
      }

      var payload = {'User': this.$store.state.users.userfull.userdata, 'Address': adr, 'BankAccount': bank_acc};

      this.$store.dispatch('users/newUser', payload)
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
          // Tabellenzeile aktualisieren
          this.$store.dispatch('users/getUserOverviewData', { root: true },{ root: true });
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.EDIT.SUCCESS.NEWUSER')});
        }
        else
        {
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.EDIT.ERROR.NEWUSER')});
        }
      }, 
      error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.EDIT.ERROR.NEWUSER')+' '+error});
      });
    },
    SaveUser: function ()
    {
      var guid = this.$store.state.users.userfull.userdata.userGuid;
      var username = this.$store.state.users.userfull.userdata.userName;
      let payload = {'UserData': this.$store.state.users.userfull.userdata};
      this.$store.dispatch('users/updateUserFullData', payload)
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
          // Tabellenzeile aktualisieren
          this.$store.dispatch('users/getUserOverviewDataSingle',guid);
          // Eigenen User bearbeitet?
          if(guid === this.getOwnUserGuid)
          {
            // Min Data in Sidebar aktualisieren
            this.$store.dispatch('users/getUserMinData', { root: true },{ root: true });
          }
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('USERADMIN.EDIT.SUCCESS.SAVEUSER').replace("*1*",username)});
        }
        else
        {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.EDIT.ERROR.SAVEUSER').replace("*1*",username)});
        }
      }, 
      error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('USERADMIN.EDIT.ERROR.SAVEUSER').replace("*1*",username)+' '+error});
      });
    },
    Cancel: function ()
    {
      this.$store.dispatch('adminpages/changePageModeUserAdmin', pagemode.VIEW);
      this.$store.dispatch('unsavedData/setUserAdmin', false);
    },
    GetOwnerType(){
      return ownertype.USER;
    },
    ValidateForm: function ()
    {
      var retVal = true;
      if(!this.ValidateEmail(this.email) || this.email == "")
      {
        retVal = false;
        this.emailFail = true;
      }
      
      if(!this.ValidateEmail(this.email2) && this.email2 != "")
      {
        retVal = false;
        this.email2Fail = true;
      }

      if(this.userName == "")
      {
        this.userNameFail = true;
        retVal = false;
      }

      if(this.userName == "")
      {
        this.userNameFail = true;
        retVal = false;
      }

      if(this.partnerID == "")
      {
        this.partnerIDFail = true;
        retVal = false;
      }

      if(this.firstName == "")
      {
        this.firstNameFail = true;
        retVal = false;
      }

      if(this.lastName == "")
      {
        this.lastNameFail = true;
        retVal = false;
      }  

      if(this.telefon == "")
      {
        this.telefonFail = true;
        retVal = false;
      }  

      if(this.address1_fk <= 0 && this.PageMode == pagemode.EDIT )
      {
        this.telefonAdress1_fkFail = true;
        retVal = false;
      }
      if(this.bank_account_fk <= 0 && this.PageMode == pagemode.EDIT )
      {
        this.bankAccountFk_fail = true;
        retVal = false;
      }      
      return retVal;
    },
    selectedAddress1Changed: function(value){
      this.address1_fk = value;
    },
    selectedAddress2Changed: function(value){
      this.address2_fk = value;
    },
    selectedAddressAccChanged: function(value){
      this.address_acc_fk = value;
    },
    selectedAddressDevChanged: function(value){
      this.address_dev_fk = value;
    },
    selectedBankAccountChanged: function(value){
      this.bank_account_fk = value;
    },
    toggleEditModeAddr1: function (){
      this.editModeAddr1 = !this.editModeAddr1;
    },
    toggleEditModeAddr2: function (){
      this.editModeAddr2 = !this.editModeAddr2;
    },
    toggleEditModeAddrAcc: function (){
      this.editModeAddrAcc = !this.editModeAddrAcc;
    },
    toggleEditModeAddrDev: function (){
      this.editModeAddrDev = !this.editModeAddrDev;
    },
    toggleEditModeBankAccount: function (){
      this.editModeBankAccount = !this.editModeBankAccount;
    }
  }
};
</script>
<style>
.con-input-upload{
  width:100%;
}
</style>